//===============================================================================
// Microsoft FastTrack for Azure
// Azure Active Directory B2C Authentication Samples
//===============================================================================
// Copyright © Microsoft Corporation.  All rights reserved.
// THIS CODE AND INFORMATION IS PROVIDED "AS IS" WITHOUT WARRANTY
// OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING BUT NOT
// LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY AND
// FITNESS FOR A PARTICULAR PURPOSE.
//===============================================================================

import { Inject, Injectable } from "@angular/core";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { AuthenticationResult, EventType, IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { InteractionStatus } from "@azure/msal-browser";
import { Observable, Subject, catchError, filter, finalize, map, mergeMap, retryWhen, shareReplay, takeUntil, throwError, timer } from "rxjs";
import { UserSession } from "./user-session.model";
import { HttpClient } from "@angular/common/http";
import { organization } from "../../shared/models/organization.model";
import { ClinicalModuleLinkItem } from "../../shared/models/clinicalModuleLinkItem.model";
import { User } from "../../shared/models/users.model";
import { LocalApplicationStorageService } from "../storage";
import { UserActivity } from "../../shared/models/user-activity";
import { UserAppActivity } from "../../shared/models/use-app-activity";

let global_B2CService = null;

@Injectable({
  providedIn: 'root',
})
export class B2cService {
  private _approles: any;
  private _orgs: any;
  private _menu: any;
  private _apps: any;
  private _approleApiCallCount = 0
  private _appApiCallCount = 0
  private _menuApiCallCount = 0
  private _orgApiCallCount = 0
  private cacheExpiryInhours: number = 1;
  private static singleTon_AuthService: B2cService = null
  private readonly _destroying$ = new Subject<void>();
  public userSession$ = new Subject<UserSession>();
  private onSuccess$ = new Subject();
  private appRoles$ = new Subject<any>();
  userAppRoles$ = this.appRoles$.asObservable();
  private orgs$ = new Subject<any>();
  userOrganization$ = this.orgs$.asObservable();
  private apps$ = new Subject<any>();
  userUserApps$ = this.apps$.asObservable();
  private menus$ = new Subject<any>();
  userMenus$ = this.menus$.asObservable();
  private settings$ = new Subject<any>();
  userSettings$ = this.settings$.asObservable();
  private isUserAppRolesAllowed$ = new Subject<boolean>();
  private isOptimizerApp = true;
  private userActivity: UserActivity;
  private userSession: UserSession;
  useLocalStorage = false;

  constructor(
    private applicationStorageService: LocalApplicationStorageService,
    private broadcastService: MsalBroadcastService,
    private authService: MsalService,
    @Inject('clientProperties') private clientProperties,
    private httpClient: HttpClient
  ) {
    //// Created singleton for synchronous exccution on logout.
    global_B2CService = B2cService.singleTon_AuthService = this

    let inactivityTime = function () {   
      let time;    
      let sessionTimeoutMinutes = clientProperties.environment.b2cConfig.sessionTimeoutMinutes; 
      const maxInactivity = sessionTimeoutMinutes * 60 * 1000;     
      // Reset timer on user interactions   
       window.onload = resetTimer;    
       document.onmousemove = resetTimer;    
       document.onkeypress = resetTimer;    
       document.onscroll = resetTimer;    
       document.onmousedown = resetTimer; // Touchscreen presses   

       function sessionTimelogout() {       
          global_B2CService.logout();
        }    
         
      function resetTimer() { 
              clearTimeout(time);        
              time = setTimeout(sessionTimelogout, maxInactivity);    
          } 
      };

    inactivityTime();

    this.authService.instance.addEventCallback((event) => {
      // set active account after redirect
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
        this.userSession = this.getClaims(payload.account.idTokenClaims);
        this.onSuccess$.next(0);
        const activity = { appCode: clientProperties.environment.appCode, onPremisesSecurityIdentifier: this.userSession.onPremisesSecurityIdentifier, userActivityTypeID: 1, userId: 0, appId:0, userActivityID:0 }
        this.logUserActivity(activity).subscribe(() => { });
      }
      this.useLocalStorage = this.clientProperties.environment.useLocalStorage != undefined && this.clientProperties.environment.useLocalStorage != null && this.clientProperties.environment.useLocalStorage.toString().toLowerCase() === 'true' ? true : false;
    });

    this.authService.handleRedirectObservable().subscribe({
      next: (authResult: AuthenticationResult) => {
        const account = this.authService.instance.getActiveAccount();
        if (!account) {
          console.log('no account found');
          // redirect anonymous user to login page
          this.authService.loginRedirect();
        }
      },
      error: (error) => {
        console.log(error);
        if (error.errorMessage.includes("AADB2C90091") || error.errorMessage.includes("AADB2C90088"))
          location.reload();
      }
    });

    this.onSuccess$.subscribe(res => {
      this.getUserSesssion();
    });


  }

  logout() {
    localStorage.removeItem("email");
    localStorage.removeItem("Email");
    localStorage.removeItem("AzureActiveDirectoryObjectId");
    localStorage.removeItem("userAppRoles");
    localStorage.removeItem("userApps");
    localStorage.removeItem("userOrganizations");
    localStorage.removeItem("clinicalModuleLinkItems");
    localStorage.removeItem("onpremisesId");
    localStorage.removeItem("AccessToken");

    /// synchronous call to api to hold the execution. Do not remove. It won't work with Observable / subscribe
    const activity = { appCode: global_B2CService.clientProperties.environment.appCode, onPremisesSecurityIdentifier: global_B2CService.userSession.onPremisesSecurityIdentifier, userActivityTypeID: 2, userId: 0, appId:0, userActivityID:0 }
    global_B2CService.logActivity(activity).then(function (){
      global_B2CService.authService.logout();
      global_B2CService.authService.getTokenCache().clear();
    });
  }

  logoutRedirect(url: string) {
    localStorage.removeItem("email");
    localStorage.removeItem("Email");
    localStorage.removeItem("AzureActiveDirectoryObjectId");
    localStorage.removeItem("userAppRoles");
    localStorage.removeItem("userApps");
    localStorage.removeItem("userOrganizations");
    localStorage.removeItem("clinicalModuleLinkItems");
    localStorage.removeItem("onpremisesId");
    localStorage.removeItem("AccessToken");
    const currentAccount = global_B2CService.authService.instance.getActiveAccount();

    /// synchronous call to api to hold the execution. Do not remove. It won't work with Observable / subscribe
    const activity = { appCode: global_B2CService.clientProperties.environment.appCode, onPremisesSecurityIdentifier: global_B2CService.userSession.onPremisesSecurityIdentifier, userActivityTypeID: 2, userId: 0, appId:0, userActivityID:0 }
    global_B2CService.logActivity(activity).then(function (){
      global_B2CService.authService.logoutRedrect(url);
      global_B2CService.authService.getTokenCache().clear();
    });
  }

  setUserSettings(onPremisesSecurityIdentifier: string, userSetting : any){
    this.updateUserSettings(onPremisesSecurityIdentifier,userSetting).subscribe( settings => {
      // console.log(settings);
    });
  }


  getUserSesssion() {
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None), ///|| status === InteractionStatus.HandleRedirect),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        if (this.authService.instance.getAllAccounts().length > 0) {
          let activeAccount = this.authService.instance.getActiveAccount();

          if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
            let accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0]);
          }

          if (this.clientProperties.environment.browserCacheExpiryInhours !== undefined) {
            this.cacheExpiryInhours = this.clientProperties.environment.browserCacheExpiryInhours;
          }
          this.clearPermissionCache(this.cacheExpiryInhours);
          this.userSession  = this.getClaims(this.authService.instance.getActiveAccount()?.idTokenClaims);
          this.userSession$.next(this.userSession);
          if ((this._appApiCallCount + this._approleApiCallCount + this._orgApiCallCount) == 0) {
            this.getUserData(this.userSession.onPremisesSecurityIdentifier);
          }
        }
        else {
          this.login();
        }
      });

  }

  private clearPermissionCache(expiryHours: number) {
    let isClearCache: boolean = false;
    expiryHours = expiryHours > 0 ? expiryHours : 1;
    var cacheDate = localStorage.getItem('cacheDateTime');
    if (cacheDate == null) {
      isClearCache = true;
    }
    else if (isNaN(new Date(cacheDate).getTime())) {
      isClearCache = true;
    }
    else {
      var hours = Math.floor(((new Date().getTime() - new Date(cacheDate).getTime()) % 86400000) / 3600000);
      if (hours >= expiryHours) {
        isClearCache = true;
      }
    }
    if (isClearCache) {
      localStorage.setItem('cacheDateTime', new Date().toString());
      localStorage.removeItem('userAppRoles');
      localStorage.removeItem('clinicalModuleLinkItems');
      localStorage.removeItem('userOrganizations');
      localStorage.removeItem('userApps');
    }
  }


  userAppRolesAllowed(allowedRoles: string[]): boolean {
    let isAllowedRole = false;
    if (this.applicationStorageService.getUserAppRoles() != null && this.applicationStorageService.getUserAppRoles().length > 0) {
      const userAppRoles = JSON.parse(
        this.applicationStorageService.getUserAppRoles()
      );
      if (userAppRoles !== null) {
        userAppRoles.some((role: any) => {
          if (allowedRoles !== undefined && allowedRoles.length > 0) {
            isAllowedRole = allowedRoles.some(
              (allowedRole) => role["appRoleName"].toLowerCase() === allowedRole.toLowerCase()
            );
            return isAllowedRole;
          }
        });
      } else {
        return isAllowedRole;
      }
    }
    return isAllowedRole;
  }

  private login() {
    this.authService.loginRedirect();
  }

  private getClaims(claims: any): UserSession {
    const userSession = new UserSession();
    if (claims && claims['extension_OnPremisesSecurityIdentifier'] != null) {
      userSession.firstName = claims['given_name'];
      userSession.lastName = claims['family_name']
      userSession.azureActiveDirectoryObjectId = claims['sub'] // userInfo.id;
      userSession.onPremisesSecurityIdentifier =
        claims['extension_OnPremisesSecurityIdentifier']
      userSession.AssignedEntityId = claims['extension_AssignedEntityId']
      userSession.UserType = claims['extension_UserType']
      userSession.IsOrgUser = claims['extension_IsOrgUser']
      userSession.email = claims['email']
      userSession.UserId = claims['extension_UserId']
      userSession.DisplayName = claims['name']
      userSession.loggedInString = 'true'
    }
    else {
      userSession.loggedInString = 'false'
    }
    return userSession;
  }

  private getUserData(onpremSid: string) {
    if (localStorage.getItem('AzureActiveDirectoryObjectId') == null) {
      this.getUserByOnPremSecurityId(onpremSid).subscribe(user => {
        localStorage.setItem('AzureActiveDirectoryObjectId', user.azureActiveDirectoryObjectId);
        localStorage.setItem("onpremisesId", onpremSid);
        localStorage.setItem("Email", user.email);
        localStorage.setItem("email", user.email);
      });
    }

    if (this.clientProperties.environment.isOptimizerApp !== undefined) {
      this.isOptimizerApp = this.clientProperties.environment.isOptimizerApp;
    }
    if (this.applicationStorageService.getUserAppRoles() == null || !this.useLocalStorage) {
      this._approleApiCallCount = 1
      this.getAppRoles(onpremSid).subscribe({
        next: userAppRoles => {
          this._approleApiCallCount = 0
          this.appRoles$.next(userAppRoles);
          if (this.useLocalStorage) {
            localStorage.setItem('userAppRoles', JSON.stringify(userAppRoles));
          }
        },
        error: err => {
          this._approleApiCallCount = 0;
          console.log(err);
        }
      });
    }
    else {
      this.appRoles$.next(JSON.parse(this.applicationStorageService.getUserAppRoles()));
    }

    if (this.isOptimizerApp) {
      if (this.applicationStorageService.getUserClinicalModuleLinkItem() == null || !this.useLocalStorage) {
        this._menuApiCallCount = 1
        this.getClinicalModuleLinkItem(onpremSid).subscribe({
          next: menus => {
            this._menuApiCallCount = 0;
            this.menus$.next(menus);
            if (this.useLocalStorage) {
              localStorage.setItem('clinicalModuleLinkItems', JSON.stringify(menus));
           }
          },
          error: err => {
            this._menuApiCallCount = 0;
            console.log(err);
          }
        });
      }
      else {
        this.menus$.next(JSON.parse(this.applicationStorageService.getUserClinicalModuleLinkItem()));
      }

      if (this.applicationStorageService.getUserOrganizations() == null || !this.useLocalStorage) {
        this._orgApiCallCount = 1
        this.getOrganizations(onpremSid).subscribe({
          next: orgs => {
            this._orgApiCallCount = 0;
            this.orgs$.next(orgs);
            if (this.useLocalStorage) {
              localStorage.setItem('userOrganizations', JSON.stringify(orgs));
            }
          },
          error: err => {
            this._orgApiCallCount = 0;
            console.log(err);
          }
        });
      }
      else {
        this.orgs$.next(JSON.parse(this.applicationStorageService.getUserOrganizations()));
      }

      if (this.applicationStorageService.getUserApps() == null || !this.useLocalStorage) {
        this._appApiCallCount = 1;
        this.getApps(onpremSid).subscribe({
          next: apps => {
            this._appApiCallCount = 0;
            this.apps$.next(apps);
            if (this.useLocalStorage) {
              localStorage.setItem('userApps', JSON.stringify(apps));
            }
          },
          error: err => {
            this._appApiCallCount = 0;
            console.log(err);
          }
        });
      }
      else {
        this.apps$.next(JSON.parse(this.applicationStorageService.getUserApps()));
      }

      this.getUserSettings(onpremSid).subscribe({
        next: settings => {
          this._appApiCallCount = 0;
          this.settings$.next(settings);
        },
        error: err => {
          this._appApiCallCount = 0;
          console.log(err);
        }
      });

    }
  }



  private getAppRoles(onPremisesSecurityIdentifier: string): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    const webApiUrl = `${this.clientProperties.environment.appConfig.apiServer.cossa}UserAppRolePortal/${onPremisesSecurityIdentifier}`;
    return this.httpClient.get<Array<any>>(webApiUrl, { headers })
      .pipe(
        map(res => res),
        retryWhen(this.genericRetryStrategy()),
        catchError(err => {
          return throwError('B2Cservice.ts- retryWhenFailedQuery()- COSSA getAppRoles - error = ', err);
        })
      );
  }

  private getApps(onPremisesSecurityIdentifier: string): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    const webApiUrl = `${this.clientProperties.environment.appConfig.apiServer.cossa}UserAppPortal/${onPremisesSecurityIdentifier}`;

    return this.httpClient.get<Array<any>>(webApiUrl, { headers })
      .pipe(
        map(res => res),
        retryWhen(this.genericRetryStrategy()),
        catchError(err => {
          return throwError('B2Cservice.ts- retryWhenFailedQuery()- COSSA getApps - error = ', err);
        })
      );
  }

  private getOrganizations(onPremisesSecurityIdentifier: string): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    const webApiUrl = `${this.clientProperties.environment.appConfig.apiServer.cossa}UserEntity/UserEntityTreeV2?onPremisesSecurityIdentifier=${onPremisesSecurityIdentifier}`;

    return this.httpClient.get<any[]>(webApiUrl, { headers })
      .pipe(
        map(res => res),
        retryWhen(this.genericRetryStrategy()),
        catchError(err => {
          return throwError('B2Cservice.ts- retryWhenFailedQuery()- COSSA getOrganizations - error = ', err);
        })
      );
  }

  private getClinicalModuleLinkItem(onPremisesSecurityIdentifier: string): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    const webApiUrl = `${this.clientProperties.environment.appConfig.apiServer.cossa}ClinicalModuleLinkItem/LeftMenu?onPremisesSecurityIdentifier=${onPremisesSecurityIdentifier}`;

    return this.httpClient.get<any[]>(webApiUrl, { headers })
      .pipe(
        map(res => res),
        retryWhen(this.genericRetryStrategy()),
        catchError(err => {
          return throwError('B2Cservice.ts- retryWhenFailedQuery()- COSSA getClinicalModuleLinkItem - error = ', err);
        })
      );
  }


  private getUserByOnPremSecurityId(onPremisesSecurityIdentifier: string): Observable<User> {
    const headers = { 'Content-Type': 'application/json' };

    const webApiUrl = `${this.clientProperties.environment.appConfig.apiServer.cossa}User/OnPremisesSecurityIdentifier/${onPremisesSecurityIdentifier}`;
    //return await this.httpClient.get<User>(webApiUrl, { headers }).toPromise();
    return this.httpClient.get<User>(webApiUrl, { headers }).pipe(
      map(res => res),
      retryWhen(this.genericRetryStrategy()),
      catchError(err => {
        return throwError('B2Cservice.ts- retryWhenFailedQuery()- COSSA getUserByOnPremSecurityId - error = ', err);
      })
    );
  }

  private getUserSettings(onPremisesSecurityIdentifier: string): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    const webApiUrl = `${this.clientProperties.environment.appConfig.apiServer.cossa}UserSetting/${onPremisesSecurityIdentifier}`;

    return this.httpClient.get<any>(webApiUrl, { headers })
      .pipe(
        map(res => res),
        retryWhen(this.genericRetryStrategy()),
        catchError(err => {
          return throwError('B2Cservice.ts- retryWhenFailedQuery()- COSSA UserSettings - error = ', err);
        })
      );
  }

  private updateUserSettings(onPremisesSecurityIdentifier: string, userSetting: any): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    const webApiUrl = `${this.clientProperties.environment.appConfig.apiServer.cossa}UserSetting/${onPremisesSecurityIdentifier}`;
    const body = userSetting;
    return this.httpClient.post<any>(webApiUrl,body, { headers })
      .pipe(
        map(res => res),
        retryWhen(this.genericRetryStrategy()),
        catchError(err => {
          return throwError('B2Cservice.ts- retryWhenFailedQuery()- COSSA Update UserSettings - error = ', err);
        })
      );
  }
  //// Async call works to log user login activity
  private logUserActivity(userActivity: any): any {
    const headers = { 'Content-Type': 'application/json' };

    const webApiUrl = `${this.clientProperties.environment.appConfig.apiServer.cossa}UserActivity`;
    const body = userActivity;
    return this.httpClient.post<UserActivity>(webApiUrl, body, { headers });

  }

  //// Synchronous call to log user logout activity. Do not remove.
  async logActivity(userActivity: any) {
    const headers = { 'Content-Type': 'application/json' };
    const webApiUrl = `${this.clientProperties.environment.appConfig.apiServer.cossa}UserActivity`;
    const body = userActivity;
    return await this.httpClient.post<UserActivity>(webApiUrl, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        return throwError('B2C auth.service.ts- user activity logout error = ', err);
      })
    ).toPromise();

  }

  async logUserAppActivity(userAppActivity: any) {
    const headers = { 'Content-Type': 'application/json' };
    const webApiUrl = `${this.clientProperties.environment.appConfig.apiServer.cossa}UserAppActivity`;
    const body = userAppActivity;
    return await this.httpClient.post<UserAppActivity>(webApiUrl, body, { headers }).pipe(
      map(res => res),
      catchError(err => {
        return throwError('B2C auth.service.ts- user app activity logout error = ', err);
      })
    ).toPromise();
  }

  private genericRetryStrategy = ({
    maxRetryAttempts = 2,
    scalingDuration = 500,
    excludedStatusCodes = [],
  }: {
    maxRetryAttempts?: number;
    scalingDuration?: number;
    excludedStatusCodes?: number[];
  } = {}) => (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((error, index) => {
        const retryAttempt = index + 1;

        if (
          retryAttempt > maxRetryAttempts ||
          excludedStatusCodes.find((e) => e === error.status) != null
        ) {
          return throwError(
            'B2Cauth.service.ts- genericRetryStrategy()- (skipping retry) error = ',
            error
          );
        }
        return timer(scalingDuration);
      }),
      finalize(() => {
        // this.progressSpinnerService.progressSpinnerBehaviorSubject.next('none');
      })
    );
  };



}  // class
