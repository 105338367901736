import { NgModule, ModuleWithProviders, Optional, Inject } from '@angular/core';
import { CPSLibAppComponent } from './cps-b2clibrary.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LocalApplicationStorageService } from './core/storage/local-application-storage.service';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { B2cService } from '../lib/core/authentication/b2c.service'

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}


export function GetMSALInstance(clientProperties): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: clientProperties.environment.b2cConfig.applicationId,
      authority: clientProperties.environment.b2cConfig.authorityBaseUrl + clientProperties.environment.b2cConfig.tenant + "/" +  clientProperties.environment.b2cConfig.signUpSignInPolicy,
      knownAuthorities: [clientProperties.environment.b2cConfig.authorityBaseUrl + clientProperties.environment.b2cConfig.tenant + "/" +  clientProperties.environment.b2cConfig.signUpSignInPolicy],
      redirectUri: clientProperties.environment.b2cConfig.redirectUri, // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
      postLogoutRedirectUri: '/', // Points to window.location.origin by default.
    },
  });
}

export function GetMSALInterceptorConfig(clientProperties): MsalInterceptorConfiguration {
 const protectedResourceMap = new Map<string, Array<string>>();
 clientProperties.environment.b2cConfig.protectedResourceMap.forEach(function(value){
  protectedResourceMap.set(value, clientProperties.environment.b2cConfig.b2cScopes );
   });
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function GetMSALGuardConfig(clientProperties): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [clientProperties.environment.b2cConfig.b2cScopes]
    },
    //loginFailedRoute: '/login-failed'
  };
}


@NgModule({ declarations: [CPSLibAppComponent],
    exports: [CPSLibAppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], // add this line
    bootstrap: [CPSLibAppComponent], imports: [RouterModule, MsalModule], providers: [
        B2cService,
        LocalApplicationStorageService,
        MsalBroadcastService,
        MsalService,
        MsalGuard,
        provideHttpClient(withInterceptorsFromDi()),
    ] })


export class CPSLibModule {
  public static forRoot(
    clientProperties?: ClientProperties
  ): ModuleWithProviders<CPSLibModule> {
    return {
      ngModule: CPSLibModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true
        },
        {
          provide: MSAL_INSTANCE,
          useValue: GetMSALInstance(clientProperties),
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useValue: GetMSALGuardConfig(clientProperties),
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useValue: GetMSALInterceptorConfig(clientProperties),
        },
        MsalBroadcastService,
        MsalService,
        MsalGuard,
        B2cService,
        LocalApplicationStorageService,
        {
          provide: 'clientProperties', // you can also use InjectionToken
          useValue: clientProperties,
        },
      ],
    };
  }

  constructor(@Optional() @Inject('clientProperties') private config) {
    if (!this.config) {
      this.config = {
        environment: {},
      };
    }
  }
}

export class ClientProperties {
  environment: {};
}
