import { Component, OnInit, Input, OnDestroy, Inject } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Users } from '../../shared/models/users.model';
import { MAT_DIALOG_DATA,  MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarModule } from '../../shared/modules/snackbar/snack-bar.module';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs';
import { fromMatSort, sortRows } from 'src/app/shared/utils/utils';
import { fromMatPaginator, paginateRows } from 'src/app/shared/utils/utils';
import { ActivatedRoute, Params } from '@angular/router';
import _ from 'lodash';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { CossaService } from '../../shared/services/cossa.service';
import { ApprolesUserDialogComponent, UserConfirmDialogModel } from '../approles-user-dialog/approles-user-dialog.component';
import moment from 'moment';

@Component({
  selector: 'app-users-list',
  templateUrl: './app-users-list.component.html',
  styleUrls: ['./app-users-list.component.scss'],
})
export class AppUsersListComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Input() filterBySecurityGroupId: string;
  @Input() filterBySecurityGroupName: string;
  @Input() filterByAppRoleId: string;
  @Input() filterByAppRoleName: string;
  @Input() filterByOrgId: string;
  @Input() filterByOrgName: string;
  public displayedRows$: Observable<Users[]>;
  public totalRows$: Observable<number>;
  private appId: number;
  private allUsers: Users[];
  private exportUsers: any;
  private userId: string;
  private securityGroupId: number;
  private onPremisesSecurityIdentifier: string;
  public currentUserId: number;
  public expansionOpened: boolean;
  public hideExpansionPanel = false;
  public pageName: string;
  displayedColumns: string[] = [
    'firstName',
    'lastName',
    'email',
    'userId', //,
   // 'securityGroupName',
    'action',
  ];
  public isOpen = false;
  private routeParamSubscription: Subscription;
  public userSession: UserSession = new UserSession();
  private userSessionSubscription: Subscription;
  public securityGroupName = '';
  public appRoleName = '';
  public orgName = '';
  public typeOfSpinner = 'loading';
  private isAdminUser = false;
  private isUserMgrUser = false;

  constructor(
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private snackBar: SnackBarModule,
    private activatedRoute: ActivatedRoute,
    private b2cService: B2cService,
    private cossaService: CossaService,
    public parentDialogRef: MatDialogRef<ApprolesUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public rowData: UserConfirmDialogModel

  ) { }

  ngOnInit(): void {
    const selectedTitle = !_.isNil(this.filterBySecurityGroupName) ? this.filterBySecurityGroupName : (this.filterByAppRoleName ? this.filterByAppRoleName : '');
    this.pageName = !_.isNil(this.activatedRoute.snapshot.data.pageDetails) ? this.activatedRoute.snapshot.data.pageDetails.title : selectedTitle + ' Users';
    this.currentUserId = 0;
    //this.expansionOpened = false;

      this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
      this.b2cService.getUserSesssion();
      this.routeParamSubscription = this.activatedRoute.params.subscribe((params: Params) => {
        this.appId = params.id;
      });
      this.orgName = "";
      this.appRoleName ="";
     // this.securityGroupName ="";
      this.isAdminUser = this.isAdmin();
      this.isUserMgrUser = this.isUserMgr();

    if (this.filterBySecurityGroupId) {
      this.getAllSecurityGroupUsers(this.filterBySecurityGroupId);
      this.securityGroupName = this.getSelectedSecurityGroupName();
    //   this.hideExpansionPanel = true;
    } else
    if (this.filterByAppRoleId) {
      this.getAllAppRoleUsers(this.filterByAppRoleId);
      this.appRoleName = this.getSelectedAppRoleName();
   //   this.hideExpansionPanel = true;
    } else if (this.filterByOrgId) {
      this.getOrgAdmins(this.filterByOrgId);
      this.orgName = this.filterByOrgName;
    //  this.hideExpansionPanel = true;
    } else {
      this.getAllUsers();
      //this.hideExpansionPanel = false;
    }

  }

  editOrgAdmin(): void{
   this.parentDialogRef.close();
  }

  getRoleMode(): string {
    return this.appSecurityManagementService.getRoleMode();
  }

  getSelectedSecurityGroupName(): string {
    this.typeOfSpinner = 'none';
    return this.appSecurityManagementService.getSecurityGroupName();
  }

  getSelectedAppRoleName(): string {
    this.typeOfSpinner = 'none';
    return this.appSecurityManagementService.getAppRoleName();
  }

  isCurrentUser(user: Users): boolean {
    if (this.currentUserId === user.userId) {
      return true;
    } else {
      return false;
    }
  }

  PanelOpened(user: Users): void {
    this.currentUserId = user.userId;
    this.expansionOpened = true;
    this.isOpen = true;
  }

  afterPanelOpened(user: Users): void {
    this.currentUserId = user.userId;
    this.expansionOpened = true;
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    const filteredUsers = this.allUsers.filter((user) => {
      return (
        user.firstName.toLowerCase().indexOf(filterValue) !== -1 ||
        user.lastName.toLowerCase().indexOf(filterValue) !== -1 ||
        (user.email && user.email.toLowerCase().indexOf(filterValue) !== -1) ||
        user.userId.toString().indexOf(filterValue) !== -1
      );
    });
    this.paginator.pageIndex = 0;
    this.setDisplayRows(filteredUsers);
  }

  setDisplayRows(userList: Users[]): void {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(userList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );

    this.displayedRows$.subscribe((dispUsers) => {
      dispUsers.filter((user) => {
        this.getSecurityGroupName(user);
        return true;
      });
    });

  }

  getSecurityGroupName(user: Users): string {
    if (user.securityGroupName && user.securityGroupName.length) {
      return user.securityGroupName;
    } else {
      this.appSecurityManagementService
        .getSecurityGroupForUser(user.userId.toString())
        .subscribe((securityGroupObject) => {
          if (securityGroupObject.securityGroupId !== undefined) {
            return (user.securityGroupName =
              securityGroupObject.securityGroupName);
          } else {
            return '';
          }
        });
    }
  }

  getAllUsers(): void {
    this.cossaService.getAllUsers().subscribe(
      (allUsersObject) => {
        this.allUsers = allUsersObject;
        this.setDisplayRows(this.allUsers);
        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.showSnackBarMessageError(error);
      }
    );
  }

  getAllSecurityGroupUsers(securityGroupId: string): void {
    this.appSecurityManagementService
      .getAllSecurityGroupUsers(securityGroupId, "true")
      .subscribe(
        (allSecurityGroupUsersObject) => {
          this.allUsers = allSecurityGroupUsersObject;
          this.setDisplayRows(this.allUsers);
        },
        (error: HttpErrorResponse) => {
          this.showSnackBarMessageError(error);
        }
      );
  }

  getAllAppRoleUsers(appRoleId: string): void {
    this.appSecurityManagementService.getAllAppRoleUsers(appRoleId).subscribe(
      (allAppRoleUsersObject) => {
        this.allUsers = allAppRoleUsersObject;
        this.setDisplayRows(this.allUsers);
      },
      (error: HttpErrorResponse) => {
        this.showSnackBarMessageError(error);
      }
    );
  }

  getOrgAdmins(orgId: string): void {
    this.appSecurityManagementService.getOrganizationAdminsWithFacility(orgId).subscribe(
      (organizationAdmins) => {
        this.allUsers = organizationAdmins;
        this.setDisplayRows(this.allUsers);
        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.showSnackBarMessageError(error);
        this.typeOfSpinner = 'none';
      });

  }

  isAdminReadOnly(): boolean {
    return this.appSecurityManagementService.getAdminReadOnlyMode();
  }

  isAdmin(): boolean {
    return this.appSecurityManagementService.getAdminMode();
  }

  isUserMgr(): boolean{
    return this.appSecurityManagementService.getUserManagementMode();
  }

  showSnackBarMessageError(error: HttpErrorResponse): void {
    this.snackBar.errorMessage(error);
  }

  closeDiaglog():void{
    this.parentDialogRef.close();
  }

  ngOnDestroy(): void {
    if (!_.isNil(this.routeParamSubscription)) {
      this.routeParamSubscription.unsubscribe();
    }
    if (!_.isNil(this.userSessionSubscription)) {
      this.userSessionSubscription.unsubscribe();
    }
  }
}
