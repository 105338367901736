import { Component, Inject, Input,Output, OnInit, ViewChild,EventEmitter } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable, Subscription, of } from 'rxjs';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { fromMatSort, sortRows } from 'src/app/shared/utils/utils';
import { fromMatPaginator, paginateRows } from 'src/app/shared/utils/utils';
import { map } from 'rxjs/operators';
import _ from 'lodash';
import { CustomErrorHandler } from '../../shared/utils/custom-error-handler';
import { AdminFacilityService } from './services/admin-facility.service';
import { MatRadioChange } from '@angular/material/radio';
import { AdminFacilityLOB } from './models/admin-facility-lob-list-model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import { ConfirmationDialogComponent, ConfirmDialogModel } from '../../shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FacilityLOB } from 'src/app/shared/models/facility-lob.model';
import { SnackBarModule } from 'src/app/shared/modules/snackbar/snack-bar.module';
import { ActionMessage, DisplayMessage } from 'src/app/shared/components/message';

@Component({
  selector: 'app-view-admin-facility-lob',
  templateUrl: './view-admin-facility-lob.component.html',
  styleUrls: ['./view-admin-facility-lob.component.scss'],
  animations: [
    trigger('detailExpand', [
     state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
     state('*', style({ height: '*', visibility: 'visible' })),
     transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
   ]),
  ],
})
export class ViewAdminFacilityLOBComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Input() orgIdInput: number;
  @Input() orgNameInput : string;
  @Input() facilityContractId: number;
  @Input() entityId: number;
  @Input()  isFromOrg: boolean;
  @Input()  isFromParentFacility: boolean;
  @Input() resetForm: Observable<boolean>;
  fromOrganization = false;
  public typeOfSpinner = 'loading';
  public displayedRows$: Observable<AdminFacilityLOB[]>;
  public totalRows$: Observable<number>;
  public appId: number;
  public pageName: string;
  private unsubscribeList: any[] = [];
  public userSession: UserSession = new UserSession();
  userSessionSubscription : Subscription;
  private adminFaciliitesList: AdminFacilityLOB[];
  selectedOption = true;
  filterValue = "";
  isLOBManager=false;
  isAdmin=false;
  isOrgAdmin=false;
  isUserMgr=false;
  isEditDisable=false;
  isInternalUserOrgAdminView=false;
  displayedColumns: string[] = ['expand', 'facilityName', 'lineOfBusinessType', 'serviceCategory', 'unitNumber', 'entityId',
  'startDate','endDate', 'action'];
  lastChild=0;

  expandedElement: any;
  isExpansionDetailRow = (i: number, row: any) => row.hasOwnProperty.call('detailRow');
  @Output() deleteEvent: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private customErrorHandler: CustomErrorHandler,
    private adminFacilityService: AdminFacilityService,
    private activatedRoute: ActivatedRoute,
    private b2cService: B2cService,
    private router: Router,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private dialog: MatDialog,
    private snackBar: SnackBarModule,
    ) { }

  ngOnInit(): void {
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();
    this.pageName = 'Facilities / LOB List';
    this.isFromOrg = (this.isFromOrg != true)? false : this.isFromOrg;

    const routeParamSubscription = this.activatedRoute.params.subscribe(
      (params: Params) => {
      this.appId = params.id;
      }
    );

    if(this.orgIdInput > 0 && this.orgIdInput !=null) {
      this.fromOrganization = true;
      this.pageName = "";
      if(this.facilityContractId > 0 && this.facilityContractId !=null) {
        this.getOrganizationFacilities(this.entityId);
      } else {
        this.getFacilitiesByOrg(this.orgIdInput);
      }
    } else {
      this.fromOrganization = false;
      this.getAdminFacilities();
    }
    if(this.resetForm){
      this.resetForm.subscribe(response => {
        if(response){
          this.selectedOption =true;
          this.filterValue = '';
          this.bindData();
        }
      });
    }
    this.isLOBManager= this.adminFacilityService.getLOBManagerMode();
    this.isAdmin= this.adminFacilityService.getAdminMode();
    this.isOrgAdmin=this.adminFacilityService.getOrganizationAdminMode();
    this.isUserMgr=this.adminFacilityService.getUserManagementMode();
    if (!this.isOrgAdmin && !this.isAdmin && !this.isLOBManager && this.isUserMgr==true) {
      this.isEditDisable=true;
    } else {
      this.isEditDisable=false;
    }
    this.isInternalUserOrgAdminView=this.appSecurityManagementService.getIsInternalUserOrgAdminView();
    }

  ngOnDestroy(): void {
    this.unsubscribeList.forEach((observable) => {
      observable.unsubscribe();
    });
  }

  bindData():void{
    if(this.orgIdInput > 0 && this.orgIdInput !=null) {
      this.fromOrganization = true;
      this.pageName = "";
      if(this.facilityContractId > 0 && this.facilityContractId !=null) {
        this.getOrganizationFacilities(this.entityId);
      } else {
        this.getFacilitiesByOrg(this.orgIdInput);
      }
    } else {
      this.fromOrganization = false;
      this.getAdminFacilities();
    }
  }

  getAdminFacilities(): void {
    this.adminFacilityService.getFacilityLOBList().subscribe(
      (response) => {
        this.adminFaciliitesList = response;
        this.adminFaciliitesList = this.adminFaciliitesList.sort((a, b) => {
          return (a.facilityName && b.facilityName) ? (a.facilityName.trim().toLocaleLowerCase() < b.facilityName.trim().toLocaleLowerCase()) ? -1 : 1: null;
        });
        this.setDisplayRows(this.adminFaciliitesList);
        this.getFilteredFacilities(this.selectedOption);
        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      });

  }

  getFacilitiesByOrg(parentEntityId: number): void {
    this.adminFacilityService.getFacilityLOBListByOrgId(parentEntityId).subscribe(
      (response) => {
        this.adminFaciliitesList = response;
        this.adminFaciliitesList = this.adminFaciliitesList.sort((a, b) => {
          return (a.facilityName && b.facilityName) ? (a.facilityName.trim().toLocaleLowerCase() < b.facilityName.trim().toLocaleLowerCase()) ? -1 : 1: null;
        });
        this.setDisplayRows(this.adminFaciliitesList);
        this.getFilteredFacilities(this.selectedOption);
        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      });

  }

  getOrganizationFacilities(parentEntityId: number): void {
    this.adminFacilityService.getOrganizationFacilityLOBList(parentEntityId).subscribe(
      (response) => {
        this.adminFaciliitesList = response;
        this.adminFaciliitesList = this.adminFaciliitesList.sort((a, b) => {
          return (a.facilityName && b.facilityName) ? (a.facilityName.trim().toLocaleLowerCase() < b.facilityName.trim().toLocaleLowerCase()) ? -1 : 1: null;
        });
        this.setDisplayRows(this.adminFaciliitesList);
        this.getFilteredFacilities(this.selectedOption);
        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      });

  }

  setDisplayRows(adminFacilitiesList: AdminFacilityLOB[]): void {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(adminFacilitiesList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
  }

  applyFilter(filterValue: string): void {
    this.filterValue = filterValue;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();

    const filteredFacilities:AdminFacilityLOB[] = [];
    this.adminFaciliitesList.forEach(facility=>{
  //if filter matches, push facilityLOB
  if ((facility.facilityName && facility.facilityName.toLowerCase().indexOf(filterValue) !== -1) ||
  (facility.lineOfBusinessType && facility.lineOfBusinessType.toLowerCase().indexOf(filterValue) !== -1) ||
  (facility.serviceCategory && facility.serviceCategory.toLowerCase().indexOf(filterValue) !== -1) ||
  (facility.entityId && facility.entityId.toString().indexOf(filterValue) !== -1) ||
  (facility.salesforceLineofBusiness && facility.salesforceLineofBusiness.toLowerCase().indexOf(filterValue) !== -1) ||
  (facility.unitNumber && facility.unitNumber.toLowerCase().indexOf(filterValue) !== -1) ||
  (facility.organization && facility.organization.toLowerCase().indexOf(filterValue) !== -1) ||
  (facility.salesforceLineofBusiness && facility.salesforceLineofBusiness.toLowerCase().indexOf(filterValue) !== -1) ||
  (facility.facilityContractId && facility.facilityContractId.toString().indexOf(filterValue) !== -1)){
    filteredFacilities.push(facility);
    return;
  }
    else {
      //if some of the children match, build a new facilityLOB child array and push

      if(facility.children != undefined && facility.children.length > 0){
        let newParent:AdminFacilityLOB;
            // eslint-disable-next-line prefer-const
            newParent = {
              facilityContractId : facility.facilityContractId,
              entityId : facility.entityId,
              facilityId : facility.facilityId,
              facilityName : facility.facilityName,
              unitNumber: facility.unitNumber,
              organization : facility.organization,
              organizationId : facility.organizationId,
              isParentEntity:facility.isParentEntity,
              isParentEntityString: facility.isParentEntityString,
              isActive : facility.isActive,
              lineOfBusinessType:facility.lineOfBusinessType,
              startDate:facility.startDate,
              endDate:facility.endDate,
              city: facility.city,
              state: facility.startDate,
              serviceCategory: facility.serviceCategory,
              lastDayOfService:facility.lastDayOfService,
              salesforceLineofBusiness: facility.salesforceLineofBusiness,
              children: [],
              isParentOfActiveChildEntity: facility.isParentOfActiveChildEntity,
              isParentOfInActiveChildEntity: facility.isParentOfInActiveChildEntity,
              isFacilityLOB: facility.isFacilityLOB,
              allowDeleteFacility: facility.allowDeleteFacility,
            }

          facility.children.forEach(fChild=>{
          //if filter matches, push child object
          if ((fChild.facilityName && fChild.facilityName.toLowerCase().indexOf(filterValue) !== -1) ||
          (fChild.lineOfBusinessType && fChild.lineOfBusinessType.toLowerCase().indexOf(filterValue) !== -1) ||
          (fChild.serviceCategory && fChild.serviceCategory.toLowerCase().indexOf(filterValue) !== -1) ||
          (fChild.entityId && fChild.entityId.toString().indexOf(filterValue) !== -1) ||
          (fChild.salesforceLineofBusiness && fChild.salesforceLineofBusiness.toLowerCase().indexOf(filterValue) !== -1) ||
          (fChild.unitNumber && fChild.unitNumber.toLowerCase().indexOf(filterValue) !== -1) ||
          (fChild.organization && fChild.organization.toLowerCase().indexOf(filterValue) !== -1) ||
          (fChild.salesforceLineofBusiness && fChild.salesforceLineofBusiness.toLowerCase().indexOf(filterValue) !== -1) ||
          (fChild.facilityContractId && fChild.facilityContractId.toString().indexOf(filterValue) !== -1)){
            newParent.children.push(fChild);
            // this.expandedElement = 'element';
            return;
          }
        });

        if(newParent.children != undefined && newParent.children.length > 0){
          filteredFacilities.push(newParent);
        }
      }
    }
  });

   const filteredFacilitiesByOptions = filteredFacilities.filter((org) => this.selectedOption == null || org.isActive == this.selectedOption);
    this.paginator.pageIndex = 0;
    this.setDisplayRows(filteredFacilitiesByOptions);
  }

  showCaret(element: any){
    return (element.isParentEntity === true && this.selectedOption === null ) || (element.isParentOfActiveChildEntity === true && this.selectedOption === true) || (element.isParentOfInActiveChildEntity === true && this.selectedOption === false)
  }

  onConditionChange(conditionChange: MatRadioChange){
    this.expandedElement = undefined;
    this.selectedOption = conditionChange.value;
    if(this.filterValue == "") {
      this.getFilteredFacilities(conditionChange.value);
    } else {
      this.applyFilter(this.filterValue);
    }
  }

  getFilteredFacilities(condition?: boolean){

    const filteredFacilitiesByOptions = this.adminFaciliitesList.filter((org) => this.selectedOption == null || org.isActive == this.selectedOption);
    this.paginator.pageIndex = 0;
    this.setDisplayRows(filteredFacilitiesByOptions);
  }

  onAdd(): void{
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/', 0 ,'addeditfacilitylob'], { queryParams:  {isEdit: false, isFromOrg : this.isFromOrg, orgName: this.orgNameInput, orgId: this.orgIdInput, isFromParentFacility: this.isFromParentFacility, parentFacilityContractId: this.facilityContractId } });
  }

  onEdit(item): void{
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/', item.entityId ,'addeditfacilitylob'], { queryParams:  {isEdit: true, isFromOrg : false, orgName: item.organization, orgId: item.organizationId, facilityContractId: item.facilityContractId } });
  }

  onDelete(facilityLOB:FacilityLOB):void{
    if (this.dialog.openDialogs && this.dialog.openDialogs.length > 0) return;
    this.openDeleteDialog(facilityLOB);
  }
  openDeleteDialog(facilityLOB:FacilityLOB): void {
    const message = 'Are you sure you want to delete "' + facilityLOB.facilityName + '" ?  ';
    const dialogData = new ConfirmDialogModel('Delete' , message);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      // width: '450px',
      data: dialogData,
      backdropClass: 'sm_panel-dialog'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
          this.deleteFacility(facilityLOB.facilityId,facilityLOB.entityId);
      }
    });
  }

  deleteFacility(facilityId:number,entityId:number): void {
    this.appSecurityManagementService.deleteFacility(facilityId.toString(), entityId.toString()).subscribe(
      (updatedRows) => {
        if (updatedRows > 0) {
          this.snackBar.successMessage(DisplayMessage.deleteMessage, ActionMessage.okAction);
          this.initAfterDelete();
        }
      },
      (error: HttpErrorResponse) => {
        this.snackBar.errorMessage(error, ActionMessage.okAction);
      }
    );
  }

  initAfterDelete():void{
    this.ngOnInit();
    this.deleteEvent.emit(true);
  }

  onChildFacilityDeleteEvent(isDeleted: boolean){
    if(isDeleted)
    {
      this.initAfterDelete();
    }
  }

  applyChildFilter(children: Array<AdminFacilityLOB>){
    children = this.selectedOption == null ? children : children.filter(p => p.isActive === this.selectedOption);
    this.lastChild = children[children.length - 1].facilityContractId;
    return children;
  }



}
